import { useMutation } from '@tanstack/react-query';

import { SharedUserGateway } from '../gateways';
import { handleResponse } from '@/core/utils';

interface MutationProps {
  login: {
    username: string;
    password: string;
  };
  shareId: string;
}

async function signIn({ login, shareId }: MutationProps) {
  return handleResponse(SharedUserGateway.signIn(login, shareId));
}

export function useShareSignIn() {
  return useMutation<any, Error, MutationProps, any>(signIn, {
    onSuccess: (data) => {
      const baseUrl = process.env.NEXT_PUBLIC_FRONT_END_BASE_URL;
      window.location.href = `${baseUrl}/login?token=${data.token}&invite_id=${data.inviteId}`;
    },
    onError: (err) => {
      console.error(err);
    }
  });
}
