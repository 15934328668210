import { useMutation } from '@tanstack/react-query';

import { SharedUserGateway } from '../gateways';
import { CreateShareUserProps } from '@/core/types';
import { handleResponse } from '@/core/utils';

interface MutationProps {
  user: CreateShareUserProps;
  shareId: string;
}

async function signUp({ user, shareId }: MutationProps) {
  return handleResponse(SharedUserGateway.signUp(user, shareId));
}

export function useShareSignUp() {
  return useMutation<any, Error, MutationProps, any>(signUp, {
    onSuccess: (data) => {
      const baseUrl = process.env.NEXT_PUBLIC_FRONT_END_BASE_URL;
      window.location.href = `${baseUrl}/login?token=${data.token}&invite_id=${data.inviteId}`;
    },
    onError: (err) => {
      console.error(err);
    }
  });
}
