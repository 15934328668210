import { useMutation } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import { SessionGateway } from '../gateways';
import { Database } from '../types';

interface MutationProps {
  database: Database;
}

async function switchDatabase({ database }: MutationProps) {
  const response = await SessionGateway.setCurrentDatabase(database.databaseId);

  if (response.ok) {
    return response.json();
  } else {
    throw new Error('Could not get current user.');
  }
}

export function useSwitchDatabase() {
  const { pathname } = useRouter();
  return useMutation(switchDatabase, {
    onSuccess: () => {
      if (pathname.includes('sharing/shared')) {
        window.location.href = `${process.env.NEXT_PUBLIC_FRONT_END_BASE_URL}/sharing/shared`;
      } else {
        window.location.href = process.env.NEXT_PUBLIC_FRONT_END_BASE_URL as string;
      }
    }
  });
}
