import { useMutation } from '@tanstack/react-query';

import { SharedLinkGateway } from '../gateways';
import { handleResponse } from '@/core/utils/response';
import { useNotificationContext } from '@/core/context';
import SuccessNotification from '@/components/notifications/success';
import { NotificationActionType } from '@/core/reducers/action-types';

interface MutationProps {
  id: string;
}

async function sendShareLink({ id }: MutationProps) {
  return handleResponse(SharedLinkGateway.invite({ id }));
}

export function useSendShareLink() {
  const { dispatch } = useNotificationContext();

  const successNotification = (email: string) => (
    <SuccessNotification
      title="Created"
      onClose={() => {
        dispatch({ type: NotificationActionType.ClosePanel });
      }}
    >
      <p>An invite to {email} has been re-sent.</p>
    </SuccessNotification>
  );

  return useMutation(sendShareLink, {
    onSuccess: ({ email }) => {
      dispatch({
        type: NotificationActionType.OpenPanel,
        payload: { component: successNotification(email) }
      });
    }
  });
}
