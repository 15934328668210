import { useMutation, useQueryClient } from '@tanstack/react-query';

import { SharedEntityGateway } from '../gateways';
import { handleResponse } from '@/core/utils/response';
import { folderKeyFactory } from '@/core/query-keys';
import { useNotificationContext } from '@/core/context';
import SuccessNotification from '@/components/notifications/success';
import { NotificationActionType } from '@/core/reducers/action-types';
import { PermissionableType } from '@imaging/types';
import { useFetchPermissionables } from '@/core/hooks/use-fetch-permissionables';

interface MutationProps {
  id: string;
}

async function removeShare({ id }: MutationProps) {
  return handleResponse(SharedEntityGateway.removeShare({ id }));
}

export function useRemoveShare() {
  const client = useQueryClient();
  const { data: permissionables } = useFetchPermissionables();
  const { dispatch } = useNotificationContext();

  const successNotification = (name: string) => (
    <SuccessNotification
      title="Removed"
      onClose={() => {
        dispatch({ type: NotificationActionType.ClosePanel });
      }}
    >
      <p>Share access for {name} has been removed.</p>
    </SuccessNotification>
  );

  return useMutation(removeShare, {
    onSuccess: ({ folder_id, user_id }) => {
      client.invalidateQueries(folderKeyFactory.byFolderId(folder_id));
      client.invalidateQueries(folderKeyFactory.folderHistory(folder_id));

      const user = permissionables?.find(
        (permissionable) =>
          permissionable.permissionable_id === user_id &&
          permissionable.permissionable_type === PermissionableType.USER
      );

      dispatch({
        type: NotificationActionType.OpenPanel,
        payload: { component: successNotification(user?.name as string) }
      });
    }
  });
}
