import { useMutation } from '@tanstack/react-query';

import { SessionGateway, SharedLinkGateway } from '../gateways';
import { handleResponse } from '@/core/utils';

interface MutationProps {
  id: string;
}

async function acceptShareLink({ id }: MutationProps) {
  return handleResponse(SharedLinkGateway.accept({ id }));
}

export function useAcceptShareLink() {
  return useMutation<any, Error, MutationProps, any>(acceptShareLink, {
    onSuccess: async ({ database_id }) => {
      SessionGateway.setCurrentDatabase(database_id).then(
        () =>
          (window.location.href = `${process.env.NEXT_PUBLIC_FRONT_END_BASE_URL}/sharing/shared`)
      );
    }
  });
}
