import React from 'react';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import { FetchedEntities, RawFetchedEntities } from '../types';
import { SharedEntityGateway } from '../gateways';
import { useNotificationContext } from '../context';
import { NotificationActionType } from '../reducers/action-types';
import ErrorNotification from '../../core/components/notifications/error';
import { handleResponse } from '@/core/utils/response';
import { entityKeyFactory } from '@/core/query-keys';

interface QueryProps {
  folderId?: string;
}

interface HookProps extends QueryProps {
  options?: UseQueryOptions<RawFetchedEntities, Error, FetchedEntities>;
}

async function fetchSharedEntities({ folderId }: QueryProps) {
  return handleResponse(SharedEntityGateway.fetch({ folderId }));
}

export function useFetchSharedEntities({ folderId, options = {} }: HookProps) {
  const { dispatch } = useNotificationContext();
  const router = useRouter();

  const errorComponent = () => (
    <ErrorNotification
      onClose={() => {
        dispatch({ type: NotificationActionType.ClosePanel });
      }}
      title="Not found"
    >
      Folder not found.
    </ErrorNotification>
  );

  return useQuery<RawFetchedEntities, Error, FetchedEntities>(
    entityKeyFactory.external(folderId),
    () => fetchSharedEntities({ folderId }),
    {
      onError: () => {
        router.push('/sharing/shared');
        dispatch({
          type: NotificationActionType.OpenPanel,
          payload: { errorComponent }
        });
      },
      ...options
    }
  );
}
