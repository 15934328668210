import { useEffect, useState } from 'react';
import { useSplitClient } from '@splitsoftware/splitio-react';

import { useCurrentUser } from '@/core/hooks';
import { FeatureFlag } from '@/core/utils';

export function useFeatureFlag(featureFlag: FeatureFlag) {
  const [isFeatureEnabled, setFeatureEnabled] = useState(false);
  const { client: splitClient, isReady, ...args } = useSplitClient();
  const { data } = useCurrentUser();

  useEffect(() => {
    if (splitClient && isReady && data) {
      const { treatment, config } = splitClient.getTreatmentWithConfig(featureFlag);

      if (treatment === 'on') {
        setFeatureEnabled(true);
      } else {
        const parsedConfig = config ? JSON.parse(config) : null;
        setFeatureEnabled(parsedConfig?.databaseIds?.includes(data?.currentDatabaseId) || false);
      }
    }
  }, [splitClient, isReady, data]);

  return { isFeatureEnabled, isReady, ...args };
}
