import { useMutation, useQueryClient } from '@tanstack/react-query';
import { handleResponse } from '@/core/utils/response';
import { folderKeyFactory } from '@/core/query-keys';
import { SharedLinkGateway } from '@/core/gateways';
import { useNotificationContext } from '@/core/context';
import SuccessNotification from '@/components/notifications/success';
import { NotificationActionType } from '@/core/reducers/action-types';

interface MutationProps {
  folder_id: string;
  invites: string[];
}

async function createShareLink({ ...props }: MutationProps) {
  return handleResponse(SharedLinkGateway.create(props));
}

export function useCreateShareLink() {
  const client = useQueryClient();
  const { dispatch } = useNotificationContext();

  const successNotification = (invites: string[]) => (
    <SuccessNotification
      title="Created"
      onClose={() => {
        dispatch({ type: NotificationActionType.ClosePanel });
      }}
    >
      <p>Invites have been sent to {invites.toString()}</p>
    </SuccessNotification>
  );

  return useMutation(createShareLink, {
    onSuccess: ({ folder_id, invites }) => {
      client.invalidateQueries(folderKeyFactory.byFolderId(folder_id));
      client.invalidateQueries(folderKeyFactory.folderHistory(folder_id));

      dispatch({
        type: NotificationActionType.OpenPanel,
        payload: { component: successNotification(invites) }
      });
    }
  });
}
