import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import type { Breadcrumb } from '../types';
import { SharedFolderGateway } from '../gateways';
import { handleResponse } from '@/core/utils/response';
import { breadcrumbKeyFactory } from '@/core/query-keys';

interface QueryProps {
  folderId?: string;
}

interface HookProps {
  folderId?: string;
  options?: UseQueryOptions<Breadcrumb[]>;
}

async function fetchSharedBreadcrumbs({ folderId }: QueryProps) {
  if (folderId) {
    return handleResponse(SharedFolderGateway.fetchBreadcrumbs({ folderId }));
  } else {
    return [];
  }
}

export function useFetchSharedBreadcrumbs({ folderId, options = {} }: HookProps) {
  return useQuery<Breadcrumb[]>(
    breadcrumbKeyFactory.sharedByParentId(folderId),
    () => fetchSharedBreadcrumbs({ folderId }),
    {
      enabled: !!folderId,
      initialData: () => [],
      ...options
    }
  );
}
