import { useMutation, useQueryClient } from '@tanstack/react-query';

import { SharedLinkGateway } from '../gateways';
import { handleResponse } from '@/core/utils/response';
import { folderKeyFactory } from '@/core/query-keys';
import { useNotificationContext } from '@/core/context';
import SuccessNotification from '@/components/notifications/success';
import { NotificationActionType } from '@/core/reducers/action-types';

interface MutationProps {
  id: string;
}

async function deleteShareLink({ id }: MutationProps) {
  return handleResponse(SharedLinkGateway.destroy({ id }));
}

export function useDeleteShareLink() {
  const client = useQueryClient();
  const { dispatch } = useNotificationContext();

  const successNotification = (email: string) => (
    <SuccessNotification
      title="Created"
      onClose={() => {
        dispatch({ type: NotificationActionType.ClosePanel });
      }}
    >
      <p>Invite to {email} has been cancelled.</p>
    </SuccessNotification>
  );

  return useMutation(deleteShareLink, {
    onSuccess: ({ folder_id, email }) => {
      client.invalidateQueries(folderKeyFactory.byFolderId(folder_id));

      dispatch({
        type: NotificationActionType.OpenPanel,
        payload: { component: successNotification(email) }
      });
    }
  });
}
