const userKeys = {
  all: ['allUsers'],
  permissionables: ['permissionables'],
  currentUser: ['currentUser'],
  currentUserTeams: ['currentUser', 'teams'],
  login: ['login'],
  logout: ['logout'],
  integrations: ['integrations'],
  jwt: ['jwt'],
  shareSignIn: ['shareSignIn'],
  shareSignUp: ['shareSignUp'],
  userById: (id: number) => [...userKeys.all, id],
  byPartner: (partner: string) => [...userKeys.integrations, partner]
};

export default userKeys;
