import { useQuery } from '@tanstack/react-query';

import { handleResponse } from '@/core/utils/response';
import { invitationKeyFactory } from '@/core/query-keys';
import { InvitationGateway } from '../gateways';
import { ShareLink } from '@/core/types';

interface QueryProps {
  id: string;
}

async function fetchInvitation({ id }: QueryProps) {
  return handleResponse(InvitationGateway.fetch({ id }));
}

export function useFetchInvitation({ id }: QueryProps) {
  return useQuery<ShareLink, Error, ShareLink>(
    invitationKeyFactory.all,
    () => fetchInvitation({ id }),
    {
      enabled: !!id
    }
  );
}
